export const homePagesearchQueries = {
  searchStep: 'search-step',
};

export const homePageServices = [
  {
    name: 'wymiana opon',
    slug: 'wymiana-opon'
  },
  {
    name: 'serwis klimatyzacji',
    slug: 'serwis-klimatyzacji-samochodowej'
  },
  {
    name: 'wymiana oleju',
    slug: 'wymiana-oleju-w-silniku'
  },
  {
    name: 'wymiana rozrządu',
    slug: 'wymiana-paska-rozrzadu'
  },
  {
    name: 'geometria kół',
    slug: 'geometria-kol'
  },
  {
    name: 'wymiana sprzęgła',
    slug: 'wymiana-sprzegla'
  },
  {
    name: 'wymiana klocków',
    slug: 'wymiana-klockow-hamulcowych'
  },
  {
    name: 'instalacje LPG',
    slug: 'instalacje-lpg-auto-gaz'
  }
];

export const homePageCities = [
  {
    name: "Białystok",
    slug: 'bialystok',
    services: homePageServices
  },
  {
    name: "Bydgoszcz",
    slug: 'bydgoszcz',
    services: homePageServices
  },
  {
    name: "Częstochowa",
    slug: 'czestochowa',
    services: homePageServices
  },
  {
    name: "Gdańsk",
    slug: 'gdansk',
    services: homePageServices
  },
  {
    name: "Gdynia",
    slug: 'gdynia',
    services: homePageServices
  },
  {
    name: "Katowice",
    slug: 'katowice',
    services: homePageServices
  },
  {
    name: "Kielce",
    slug: 'kielce',
    services: homePageServices
  },
  {
    name: "Kraków",
    slug: 'krakow',
    services: homePageServices
  },
  {
    name: "Lublin",
    slug: 'lublin',
    services: homePageServices
  },
  {
    name: "Łódź",
    slug: 'lodz',
    services: homePageServices
  },
  {
    name: "Olsztyn",
    slug: 'olsztyn',
    services: homePageServices
  },
  {
    name: "Poznań",
    slug: 'poznan',
    services: homePageServices
  },
  {
    name: "Rzeszów",
    slug: 'rzeszow',
    services: homePageServices
  },
  {
    name: "Szczecin",
    slug: 'szczecin',
    services: homePageServices
  },
  {
    name: "Warszawa",
    slug: 'warszawa',
    services: homePageServices
  },
  {
    name: "Wrocław",
    slug: 'wroclaw',
    services: homePageServices
  }
];

export const homePageCarsCities = [
  {
    name: "Gdańsk",
    slug: 'gdansk'
  },
  {
    name: "Kraków",
    slug: 'krakow'
  },
  {
    name: "Poznań",
    slug: 'poznan'
  },
  {
    name: "Szczecin",
    slug: 'szczecin'
  },
  {
    name: "Warszawa",
    slug: 'warszawa'
  },
  {
    name: "Wrocław",
    slug: 'wroclaw'
  },
  {
    name: "Łódź",
    slug: 'lodz'
  }
];

export const homePageBrands = [
  {
    name: 'Alfa Romeo',
    slug: 'alfa-romeo',
    cities: homePageCarsCities
  },
  {
    name: 'Audi',
    slug: 'audi',
    cities: homePageCarsCities
  },
  {
    name: 'BMW',
    slug: 'bmw',
    cities: homePageCarsCities
  },
  {
    name: 'Chevrolet',
    slug: 'chevrolet',
    cities: homePageCarsCities
  },
  {
    name: 'Chrysler',
    slug: 'chrysler',
    cities: homePageCarsCities
  },
  {
    name: 'Citroen',
    slug: 'citroen',
    cities: homePageCarsCities
  },
  {
    name: 'Dacia',
    slug: 'dacia',
    cities: homePageCarsCities
  },
  {
    name: 'Fiat',
    slug: 'fiat',
    cities: homePageCarsCities
  },

  {
    name: 'Ford',
    slug: 'ford',
    cities: homePageCarsCities
  },
  {
    name: 'Honda',
    slug: 'honda',
    cities: homePageCarsCities
  },
  {
    name: 'Hyundai',
    slug: 'hyundai',
    cities: homePageCarsCities
  },
  {
    name: 'Jeep',
    slug: 'jeep',
    cities: homePageCarsCities
  },
  {
    name: 'Kia',
    slug: 'kia',
    cities: homePageCarsCities
  },
  {
    name: 'Land Rover',
    slug: 'land-rover',
    cities: homePageCarsCities
  },
  {
    name: 'Lexus',
    slug: 'lexus',
    cities: homePageCarsCities
  },
  {
    name: 'Mazda',
    slug: 'mazda',
    cities: homePageCarsCities
  }
];

export const abTestServices = [
  {
    "description": "",
    "id": "",
    "name": "Naprawa zawieszenia",
    "popular": false,
    "price": {
      "priceFrom": 0,
      "priceTo": 0
    },
    "zilo": {
      "categoryId": "2fb8fb93-ef7c-47a4-998d-2b4a8fa29f66",
      "serviceId": "9f1331f2-66c0-4341-ad06-cc6f074e1857"
    }
  },
  {
    "description": "",
    "id": "",
    "name": "Naprawa hamulców",
    "popular": false,
    "price": {
      "priceFrom": 0,
      "priceTo": 0
    },
    "zilo": {
      "categoryId": "2fb8fb93-ef7c-47a4-998d-2b4a8fa29f66",
      "serviceId": "9f1331f2-66c0-4341-ad06-cc6f074e1857"
    }
  },
  {
    "description": "",
    "id": "",
    "name": "Naprawa silnika",
    "popular": false,
    "price": {
      "priceFrom": 0,
      "priceTo": 0
    },
    "zilo": {
      "categoryId": "2fb8fb93-ef7c-47a4-998d-2b4a8fa29f66",
      "serviceId": "9f1331f2-66c0-4341-ad06-cc6f074e1857"
    }
  },
  {
    "description": "",
    "id": "",
    "name": "Naprawa skrzyni biegów",
    "popular": false,
    "price": {
      "priceFrom": 0,
      "priceTo": 0
    },
    "zilo": {
      "categoryId": "2fb8fb93-ef7c-47a4-998d-2b4a8fa29f66",
      "serviceId": "9f1331f2-66c0-4341-ad06-cc6f074e1857"
    }
  },
  {
    "description": "",
    "id": "",
    "name": "Naprawa układu kierowniczego",
    "popular": false,
    "price": {
      "priceFrom": 0,
      "priceTo": 0
    },
    "zilo": {
      "categoryId": "2fb8fb93-ef7c-47a4-998d-2b4a8fa29f66",
      "serviceId": "9f1331f2-66c0-4341-ad06-cc6f074e1857"
    }
  },
  {
    "description": "",
    "id": "",
    "name": "Naprawa elektryki",
    "popular": false,
    "price": {
      "priceFrom": 0,
      "priceTo": 0
    },
    "zilo": {
      "categoryId": "2fb8fb93-ef7c-47a4-998d-2b4a8fa29f66",
      "serviceId": "9f1331f2-66c0-4341-ad06-cc6f074e1857"
    }
  },
  {
    "description": "",
    "id": "",
    "name": "Naprawa układu wydechowego",
    "popular": false,
    "price": {
      "priceFrom": 0,
      "priceTo": 0
    },
    "zilo": {
      "categoryId": "2fb8fb93-ef7c-47a4-998d-2b4a8fa29f66",
      "serviceId": "9f1331f2-66c0-4341-ad06-cc6f074e1857"
    }
  },
  {
    "description": "",
    "id": "",
    "name": "Naprawa klimatyzacji",
    "popular": false,
    "price": {
      "priceFrom": 0,
      "priceTo": 0
    },
    "zilo": {
      "categoryId": "2fb8fb93-ef7c-47a4-998d-2b4a8fa29f66",
      "serviceId": "9f1331f2-66c0-4341-ad06-cc6f074e1857"
    }
  },
  {
    "description": "",
    "id": "",
    "name": "Diagnostyka usterki",
    "popular": false,
    "price": {
      "priceFrom": 0,
      "priceTo": 0
    },
    "zilo": {
      "categoryId": "2fb8fb93-ef7c-47a4-998d-2b4a8fa29f66",
      "serviceId": "9f1331f2-66c0-4341-ad06-cc6f074e1857"
    }
  }
];


export const abTestCategories = [
  {
    name: 'Elektryka',
    subname: 'Naprawa elektryki',
    icon: '1',
    show: true,
    reasons: [
      'Nie działa światło',
      'Akumulator nie ładuje',
      'Silnik nie odpala',
      'Brak radia',
      'Świeci kontrolka'
    ]
  },
  {
    name: 'Hamulce',
    subname: 'Naprawa hamulców',
    icon: '2',
    show: true,
    reasons: [
      'Pisk przy hamowaniu',
      'Słabe hamowanie',
      'Wibracje przy hamowaniu',
      'Miękki pedał',
      'Kontrolka ABS'
    ]
  },
  {
    name: 'Klimatyzacja',
    subname: 'Naprawa klimatyzacji',
    icon: '3',
    show: true,
    reasons: [
      'Słabo chłodzi',
      'Nie chłodzi',
      'Zapach z nawiewu',
      'Hałas z klimy',
      'Problem z nawiewem'
    ]
  },
  {
    name: 'Prowadzenie',
    subname: 'Naprawa układu kierowniczego',
    icon: '4',
    show: true,
    reasons: [
      'Auto ściąga',
      'Luz na kierownicy',
      'Drgania kierownicy',
      'Problem ze zbieżnością',
      'Nierówne zużycie opon'
    ]
  },
  {
    name: 'Silnik',
    subname: 'Naprawa silnika',
    icon: '5',
    show: true,
    reasons: [
      'Check Engine',
      'Brak mocy',
      'Silnik szarpie',
      'Nie odpala',
      'Stuki w silniku'
    ]
  },
  {
    name: 'Skrzynia',
    subname: 'Naprawa skrzyni biegów',
    icon: '6',
    show: true,
    reasons: [
      'Biegi nie wchodzą',
      'Hałasy ze skrzyni',
      'Wyje na biegu',
      'Nie rusza na biegu',
      'Ślizga sprzęgło'
    ]
  },
  {
    name: 'Wydech',
    subname: 'Naprawa układu wydechowego',
    icon: '7',
    show: true,
    reasons: [
      'Głośny wydech',
      'Dym z rury',
      'Zapach spalin',
      'Luźny tłumik',
      'Spadek mocy'
    ]
  },
  {
    name: 'Zawieszenie',
    subname: 'Naprawa zawieszenia',
    icon: '8',
    show: true,
    reasons: [
      'Stuki na dziurach',
      'Przechyla się',
      'Uszkodzony amortyzator',
      'Nierówne zużycie opon',
      'Opadająca strona auta'
    ]
  },
  {
    name: 'Inne',
    subname: 'Inna naprawa',
    icon: '9',
    show: false,
    reasons: []
  }
];
