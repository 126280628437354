<template>
  <main
    class="homepage"
    itemscope
    itemtype="http://schema.org/AutomotiveBusiness"
  >
    <HomePageSearch :formFields="formWrapper.form.value.fields" />
    <ClientOnly>
      <DmModal
        v-model="modal"
        :title="modalTitle"
      >
        <HomePageSearchModal
          :formWrapper="formWrapper"
          :foundServices="foundServices"
          :foundWorkshops="foundWorkshops"
          :loading="loading"
          :typing="typing"
          v-if="searchModal === EHomePageSearchStep.ServiceOrWorkshop"
        />
        <HomePageSearchModalCity
          :formWrapper="formWrapper"
          :foundDistricts="foundDistricts"
          :foundCities="foundCities"
          :loading="loading"
          :typing="typing"
          v-else-if="searchModal === EHomePageSearchStep.CityOrDistrict"
        />
      </DmModal>
    </ClientOnly>
    <HomePageBenefits />
    <HomePageRecommended />
    <HomePageBrands />
    <HomePageArticles
      v-if="homepageData"
      :articles="homepageData.articles"
    />
    <HomePageCounsels
      v-if="homepageData"
      :counsels="homepageData.counsels"
    />
    <HomePageComments
      v-if="homepageData"
      :comments="homepageData.comments"
    />
  </main>
  <DmLoader
    v-if="loading || typing"
    icon-size="icon-lg"
  />
</template>

<script
  setup
  lang="ts"
>
import HomePageSearch from '@/components/HomePage/Search/HomePageSearch.vue';
import HomePageSearchModal from '@/components/HomePage/Search/HomePageSearchModal.vue';
import HomePageSearchModalCity from '@/components/HomePage/Search/HomePageSearchModalCity.vue';
import HomePageBenefits from '@/components/HomePage/Other/HomePageBenefits.vue';
import HomePageRecommended from '@/components/HomePage/Other/HomePageRecommended.vue';
import HomePageCounsels from '@/components/HomePage/Other/HomePageCounsels.vue';
import HomePageArticles from '@/components/HomePage/Other/HomePageArticles.vue';
import HomePageBrands from '@/components/HomePage/Other/HomePageBrands.vue';
import HomePageComments from '@/components/HomePage/Other/HomePageComments.vue';

import { DmModal, DmLoader } from '@dobrymechanik/vue-ui-v2';
import { homePagesearchQueries } from '@/models/consts';
import { EHomePageSearchStep } from '@/models/enums';

import { homepageApi } from '@/api/homepage-instance';

import debounce from 'debounce';

export interface IHomePageSearchlFormFields {
  searchServiceOrWorkspace: string;
  searchCityOrDistrict: string;
  serviceName: string;
  serviceSlugName: string;
}

useSeoMeta({
  title: () => 'Znajdź mechanika • Umów wizytę w warsztacie • DobryMechanik.pl',
  description: () => 'Znajdź dobrego mechanika samochodowego. Sprawdź opinie, umów się szybko i wygodnie do sprawdzonego warsztatu samochodowego w Twojej okolicy.',
  ogTitle: () => 'Znajdź mechanika • Umów wizytę w warsztacie • DobryMechanik.pl',
  ogDescription: () => 'Znajdź dobrego mechanika samochodowego. Sprawdź opinie, umów się szybko i wygodnie do sprawdzonego warsztatu samochodowego w Twojej okolicy.',
  robots: () => 'index, follow',
  ogType: () => 'article',
  ogImage: () => 'https://dobrymechanik.pl/img/banners/og_image.jpg',
  ogSiteName: () => 'dobrymechanik.pl',
  ogUrl: () => 'https://dobrymechanik.pl'
});

useHead({
  meta: [
    {
      property: 'fb:page_id',
      content: '278611051076'
    },
    {
      name: 'verify-v1',
      content: 'vq44RBVSLNGVzt/MUCQGn/J6DsCbta0LOGJerCieh74='
    },
    {
      name: 'google-site-verification',
      content: 'uoexwv3GK98JOFIWb9X2SRDqJxpJFJBHMF-DhH2XS5I'
    }
  ],
  link: [
    { rel: 'canonical', href: 'https://dobrymechanik.pl' },
    { rel: 'search', type: 'application/opensearchdescription+xml', title: 'www.dobrymechanik.pl', href: 'https://dobrymechanik.pl/opensearch.xml' },
    { rel: 'preconnect', href: '//cookie-cdn.cookiepro.com' },
    { rel: 'preconnect', href: '//googletagmanager.com' },
    { rel: 'preconnect', href: '//www.google-analytics.com' },
    { rel: 'preconnect', href: '//eu.posthog.com' },
    { rel: 'preload', href: 'https://googletagmanager.com/gtm.js?id=GTM-TTC4DXZ', as: 'script' },
    { rel: 'preload', href: 'https://google-analytics.com/analytics.js', as: 'script' },
    { rel: 'dns-prefetch', href: '//imagedelivery.net' }
  ],
  script: [
    {
      type: 'application/ld+json', children: {
        "@context": "http://schema.org",
        "@type": "WebSite",
        "name": "DobryMechanik.pl Sp. z o.o.",
        "url": "https://dobrymechanik.pl/",
        "sameAs": [
          "https://www.facebook.com/dobrymechanik",
          "https://www.linkedin.com/company/dobrymechanik-pl-sp--z-o-o-"
        ],
        "potentialAction": [{
          "@type": "SearchAction",
          "target": "https://dobrymechanik.pl/szukaj/?szukaj={search_term_string}",
          "query-input": "required name=search_term_string"
        }]
      }
    }
  ]
});

// use
const route = useRoute();
const router = useRouter();
const { $posthog } = useNuxtApp();

const formWrapper = useFormCreator<IHomePageSearchlFormFields>({
  searchServiceOrWorkspace: '',
  searchCityOrDistrict: '',
  serviceName: '',
  serviceSlugName: ''
});

// reactive
const modal = ref(false);
const loading = ref(false);
const typing = ref(false);
const foundServices = ref([]);
const foundWorkshops = ref([]);
const foundCities = ref([]);
const foundDistricts = ref([]);

// computed
const searchModal = computed(() => {
  return route.query[homePagesearchQueries.searchStep] as EHomePageSearchStep | undefined;
});
const modalTitle = computed(() => {
  switch (searchModal.value) {
    case EHomePageSearchStep.ServiceOrWorkshop:
      return 'Usługa lub warsztat';
    case EHomePageSearchStep.CityOrDistrict:
      return 'Miasto lub dzielnica';
    default:
      return '';
  }
});

// watch
watch(() => route.query, (queries) => {
  modal.value = !!queries[homePagesearchQueries.searchStep];
}, {
  immediate: true
});

watch(() => modal.value, (value) => {
  if (!value) {
    const routeName = route.name;
    if (!routeName) return;
    router.push({ name: routeName });
  }
});

watch(() => formWrapper.form.value.fields.searchServiceOrWorkspace, (value) => {
  if (value.length >= 3) {
    typing.value = true;
    debounceSearchServicesOrWorkshops(value);
  } else {
    foundServices.value = [];
    foundWorkshops.value = [];
  }
});

watch(() => formWrapper.form.value.fields.searchCityOrDistrict, (value) => {
  if (value.length >= 3) {
    typing.value = true;
    debounceSearchCityOrDistrict(value);
  } else {
    foundDistricts.value = [];
    foundCities.value = [];
  }
});

const debounceSearchServicesOrWorkshops = debounce(searchServicesOrWorkshops, 500);
const debounceSearchCityOrDistrict = debounce(searchCityOrDistrict, 500);

async function searchCityOrDistrict(val: string) {
  loading.value = true;
  typing.value = false;

  try {
    const { data } = await homepageApi.get(`/api/homepage/search-cities-and-quarters`, {
      params: {
        'city_or_quarter': val
      }
    });
    foundCities.value = data.cities;
    foundDistricts.value = data.quarters;

    if (!foundCities.value.length || !foundDistricts.value.length) {
      $posthog()?.capture('no-results', {
        value: val,
        page: 'homepage',
        type: 'cities-or-districts'
      });
    }
  } catch {

  } finally {
    loading.value = false;
  }

}

async function searchServicesOrWorkshops(val: string) {
  loading.value = true;
  typing.value = false;

  try {
    const GETservicesResponse = GETservices(val);
    const GETworkshopsResponse = GETworkshops(val);

    const [services, workshops] = await Promise.all([
      (await GETservicesResponse)?.data,
      (await GETworkshopsResponse)?.data
    ]);
    foundServices.value = services;
    foundWorkshops.value = workshops;

    if (!foundServices.value.length || !foundWorkshops.value.length) {
      $posthog()?.capture('no-results', {
        value: val,
        page: 'homepage',
        type: 'services-or-workshops'
      });
    }
  } catch {

  } finally {
    loading.value = false;
  }
};

// methods 
const GETworkshops = async (val: string) => {
  const data = await homepageApi.get(`/api/homepage/search-mechanics`, {
    params: {
      'garage_name': val
    }
  });
  return data;
};

const GETservices = async (val: string) => {
  const data = await homepageApi.get(`/api/homepage/search-services`, {
    params: {
      'service_name': val
    }
  });
  return data;
};

// methods
const { data: homepageData, pending, error, refresh } = await useAsyncData(
  "garageDataForServices",
  async () => {
    const GETComments = homepageApi.get(`/api/homepage/comments`);
    const GETArticles = homepageApi.get(`/api/homepage/articles`);
    const GETCounsels = homepageApi.get(`/api/homepage/questions`);

    const [comments, articles, counsels] =
      await Promise.all([
        (await GETComments).data,
        (await GETArticles).data,
        (await GETCounsels).data,
      ]);
    return { comments, articles, counsels };
  }, {
  lazy: true
});

onMounted(async () => {
  setTimeout(() => {
    // POSTHOG
    $posthog()?.capture('$pageview');
    //
  }, 0);
});

</script>

<style lang="scss">
.homepage {
  .modal__slot {
    @media (min-width: $breakpoint) {
      max-width: 718px !important;
      width: 718px !important;
      height: 660px !important;
    }

    @media (min-width: 1024px) {
      max-width: 950px !important;
      width: 950px !important;
      height: 660px !important;
    }
  }
}
</style>